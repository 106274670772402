import { Role } from '@/helpers';

export default [
    {
        path: 'website',
        name: 'Website',
        component: () => import('@/components/PassThrough.vue'),
        meta: {
            authorize: [Role.admin, Role.manager, Role.superadmin],
            sidebar: true,
            icon: 'fa-internet-explorer',
            title: 'Контент',
        },
        children: [

            // Страницы
            {
                path: 'pages',
                name: 'ContentPages',
                component: () => import(/* webpackChunkName: "contentPages" */ '@/pages/Admin/ContentPages.vue'),
                meta: {
                    authorize: [Role.admin, Role.superadmin],
                    sidebar: true,
                    icon: 'fa-edit',
                    title: 'Страницы сайта',
                },
            },
            {
                path: 'pages/create',
                name: 'ContentCreate',
                component: () => import(/* webpackChunkName: "contentDetailPage" */ '@/pages/Admin/ContentDetail.vue'),
                meta: {
                    authorize: [Role.admin, Role.superadmin],
                    icon: 'flaticon-settings-5',
                    title: 'Добавить страницу сайта',
                },
            },
            {
                path: 'pages/:id/edit',
                name: 'ContentEdit',
                component: () => import(/* webpackChunkName: "contentDetailPage" */ '@/pages/Admin/ContentDetail.vue'),
                meta: {
                    authorize: [Role.admin, Role.superadmin],
                    icon: 'flaticon-settings-5',
                    title: 'Детали страницы сайта',
                },
            },

            // Блог
            {
                path: 'article',
                name: 'BlogArticlePassThrough',
                component: () => import('@/components/PassThrough.vue'),
                meta: {
                    authorize: [Role.admin, Role.manager, Role.superadmin],
                    sidebar: true,
                    icon: 'fa-pencil-square-o',
                    title: 'Блог',
                },
                children: [
                    {
                        path: '',
                        name: 'BlogArticles',
                        component: () => import('@/pages/Admin/Blog/Article/BlogArticles.vue'),
                        meta: {
                            authorize: [Role.admin, Role.manager, Role.superadmin],
                            title: 'Статьи блога',
                        },
                    },
                    {
                        path: 'create',
                        name: 'BlogArticleCreate',
                        component: () => import('@/pages/Admin/Blog/Article/BlogArticleDetail.vue'),
                        meta: {
                            authorize: [Role.admin, Role.manager, Role.superadmin],
                            title: 'Создание статьи блога',
                        },
                    },
                    {
                        path: ':id/edit',
                        name: 'BlogArticleEdit',
                        component: () => import('@/pages/Admin/Blog/Article/BlogArticleDetail.vue'),
                        meta: {
                            authorize: [Role.admin, Role.manager, Role.superadmin],
                            title: 'Редактирование статьи блога',
                        },
                    },
                ],
            },
            {
                path: 'category',
                name: 'BlogCategoryPassThrough',
                component: () => import('@/components/PassThrough.vue'),
                meta: {
                    authorize: [Role.admin, Role.manager, Role.superadmin],
                    sidebar: true,
                    icon: 'fa-pencil-square-o',
                    title: 'Категории блога',
                },
                children: [
                    {
                        path: '',
                        name: 'BlogCategories',
                        component: () => import('@/pages/Admin/Blog/Category/BlogCategories.vue'),
                        meta: {
                            authorize: [Role.admin, Role.manager, Role.superadmin],
                            title: 'Категории блога',
                        },
                    },
                    {
                        path: 'create',
                        name: 'BlogCategoryCreate',
                        component: () => import('@/pages/Admin/Blog/Category/BlogCategoryDetail.vue'),
                        meta: {
                            authorize: [Role.admin, Role.manager, Role.superadmin],
                            title: 'Создание категории блога',
                        },
                    },
                    {
                        path: ':id/edit',
                        name: 'BlogCategoryEdit',
                        component: () => import('@/pages/Admin/Blog/Category/BlogCategoryDetail.vue'),
                        meta: {
                            authorize: [Role.admin, Role.manager, Role.superadmin],
                            title: 'Редактирование категории блога',
                        },
                    },
                ],
            },

            // Файлы
            {
                path: 'files-list',
                name: 'FilesManager',
                component: () => import(/* webpackChunkName: "productList" */ '@/pages/Admin/Files/FilesManager.vue'),
                meta: {
                    sidebar: true,
                    authorize: [Role.admin, Role.superadmin],
                    icon: 'fa-file',
                    title: 'Файлы',
                },
            },

            // Абонементы
            {
                path: 'products',
                name: 'ProductList',
                component: () => import(/* webpackChunkName: "productList" */ '@/pages/Admin/Products/ProductList.vue'),
                meta: {
                    sidebar: true,
                    authorize: [Role.admin, Role.superadmin],
                    icon: 'fa-dollar',
                    title: 'Абонементы',
                },
            },

            // Промокоды
            {
                path: 'promocodes',
                name: 'Promocodes',
                component: () => import(/* webpackChunkName: "login" */ '@/pages/Admin/Promocodes'),
                meta: {
                    authorize: [Role.admin, Role.manager, Role.superadmin],
                    sidebar: true,
                    icon: 'fa-ticket',
                    title: 'Промокоды',
                },
            },
            {
                path: 'promocodes/create',
                name: 'PromocodesCreate',
                component: () => import(/* webpackChunkName: "login" */ '@/pages/Admin/PromocodesDetail'),
                meta: {
                    authorize: [Role.admin, Role.manager, Role.superadmin],
                    // sidebar: ,
                    icon: 'fa-ticket',
                    title: 'Промокоды',
                },
            },
            {
                path: 'promocodes/:id/edit',
                name: 'PromocodesEdit',
                component: () => import(/* webpackChunkName: "login" */ '@/pages/Admin/PromocodesDetail'),
                meta: {
                    authorize: [Role.admin, Role.manager, Role.superadmin],
                    // sidebar: ,
                    icon: 'fa-ticket',
                    title: 'Промокоды',
                },
            },

            // Турниры
            {
                path: 'tournaments',
                name: 'TournamentsPage',
                component: () => import(/* webpackChunkName: "tournamentsPage" */ '@/pages/Admin/Tournaments.vue'),
                meta: {
                    authorize: [Role.admin, Role.superadmin],
                    sidebar: true,
                    icon: 'fa-trophy',
                    title: 'Турниры',
                },
            },
            {
                path: 'tournaments/create',
                name: 'TournamentCreate',
                component: () => import(/* webpackChunkName: "tournamentDetailPage" */ '@/pages/Admin/TournamentsDetail.vue'),
                meta: {
                    authorize: [Role.admin, Role.superadmin],
                    icon: 'flaticon-settings-5',
                    title: 'Добавить турнир',
                },
            },
            {
                path: 'tournaments/:id/edit',
                name: 'TournamentsEdit',
                component: () => import(/* webpackChunkName: "tournamentDetailPage" */ '@/pages/Admin/TournamentsDetail.vue'),
                meta: {
                    authorize: [Role.admin, Role.superadmin],
                    icon: 'flaticon-settings-5',
                    title: 'Детали турнира',
                },
            },

            // Отзывы
            {
                path: 'reviews',
                name: 'ReviewsList',
                component: () => import(/* webpackChunkName: "teacherList" */ '@/pages/Admin/ReviewsList.vue'),
                meta: {
                    authorize: [Role.admin, Role.manager, Role.superadmin],
                    sidebar: true,
                    icon: 'fa-comments-o',
                    title: 'Отзывы',
                },
            },
            {
                path: 'reviews/create',
                name: 'ReviewCreate',
                component: () => import(/* webpackChunkName: "teacherList" */ '@/pages/Admin/ReviewCreate.vue'),
                meta: {
                    authorize: [Role.admin, Role.manager, Role.superadmin],
                    icon: 'fa-comments-o',
                    title: 'Отзывы',
                }
            },
            {
                path: 'reviews/:id',
                name: 'ReviewDetail',
                component: () => import(/* webpackChunkName: "teacherList" */ '@/pages/Admin/ReviewDetail.vue'),
                meta: {
                    authorize: [Role.admin, Role.manager, Role.superadmin],
                    icon: 'fa-comments-o',
                    title: 'Отзывы',
                }
            },
        ],
    },
]