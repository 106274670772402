<template>
    <div class="auth-page">
        <b-container>
            <h5 class="auth-logo">
            </h5>
            <Widget class="widget-auth mx-auto" title="<h3 class='mt-0'>Авторизация</h3>" customHeader>
                <p class="widget-auth-info">
                    Введите логин для того чтобы войти
                </p>
                <form class="mt" @submit.prevent="login">
                    <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
                        {{ errorMessage }}
                    </b-alert>
                    <div class="form-group">
                        <input class="form-control no-border" ref="email" required type="text" name="email"
                               placeholder="Логин"/>
                    </div>
                    <div class="form-group">
                        <input class="form-control no-border" ref="password" required type="password" name="password"
                               placeholder="Пароль"/>
                    </div>
                    <b-button type="submit" size="sm" class="auth-btn mb-3" variant="inverse">Войти</b-button>
                </form>
            </Widget>
        </b-container>
        <footer class="auth-footer">
<!--            2021 - {{ (new Date()).getFullYear() }} &copy; Сделано в <a href="https://koyutech.ru" original-href="https://koyu.tech" target="_blank">Koyu.Tech</a>-->
        </footer>
    </div>
</template>

<script>
    import Widget from '@/components/Widget/Widget';
    import { authenticationService } from '@/core/services';
    import { mapMutations, mapActions } from 'vuex';

    export default {
        name: 'LoginPage',
        components: {Widget},
        data() {
            return {
                errorMessage: null,
            };
        },
        methods: {
            ...mapActions({
                fetchSettingsList: 'settings/fetchSettingsList'
            }),
            ...mapMutations({
                setRole: 'user/setRole',
                setName: 'user/setName',
                setAuth: 'user/setAuth',
                setConstants: 'common/setConstants',
            }),
            async login() {
                this.errorMessage = null;
                const email = this.$refs.email.value;
                const password = this.$refs.password.value;

                if (email.length !== 0 && password.length !== 0) {
                    try {
                        const result = await authenticationService.login(email, password);
                        if (result.data.status === 'success') {
                            await this.fetchSettingsList();
                            this.setRole(result.data.user_role);
                            this.setName(result.data.user_name);
                            this.setAuth(true);
                            this.setConstants(result.data.constants);
                            this.$router.push(result.data.route);
                        } else {
                            this.errorMessage = result.data.message;
                        }
                    } catch (e) {
                        this.errorMessage = e.message;
                        console.error(e)
                    }
                    this.loading = false;
                }
            },
        },
        created() {
            this.returnUrl = this.$route.query.returnUrl || '/';
        },
    };
</script>
