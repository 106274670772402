import toastError from '@/utils/toast';

export default {
    namespaced: true,
    state: {
        blogCategories: null,
        blogCategoryDetail: null,
        pageMeta: {
            total: 0,
            per_page: 100
        },
    },
    mutations: {
        setBlogCategories(state, payload) {
            state.blogCategories = payload;
        },
        setBlogCategoryDetail(state, payload) {
            state.blogCategoryDetail = payload;
        },
        setPageMeta(state, payload) {
            state.pageMeta = payload;
        },
        deleteRow(state, index) {
            state.blogCategories.splice(index, 1);
        }
    },
    getters: {
        getBlogCategories(state) {
            return state.blogCategories;
        },
        getBlogCategoryDetail(state) {
            return state.blogCategoryDetail;
        },
        getPageMeta(state) {
            return state.pageMeta;
        }
    },
    actions: {
        deleteRow({ commit }, index) {
          commit('deleteRow', index)
        },
        async loadBlogCategories({ commit }, params = null) {
            if (params && params.status && typeof params.status === 'string') {
                params.status = [parseInt(params.status)];
            }

            let error_message = 'Не удалось загрузить категории блога';

            try {
                const response = await this.$axios.get(`/crm/blog-categories`, { params: {...params} });

                if (response) {
                    if (response.data.data) commit('setBlogCategories', response.data.data);
                    if (response.data.meta) commit('setPageMeta', response.data.meta);
                } else {
                    if (response.message) error_message = response.message;

                    toastError(error_message);
                }
                
            } catch(e) {
                if (e.message) error_message = e.message;

                toastError(error_message);
            }
        },
        async findBlogCategories({ commit }, searchWord) {
            let error_message = 'Не удалось найти категории блога';

            try {
                const response = await this.$axios.get(`/crm/blog-articles`, {
                    params: {
                        search: searchWord,
                    }
                });

                if (response.success) {
                    if (response.data.data) commit('setBlogCategories', response.data.data);
                    if (response.data.meta) commit('setPageMeta', response.data.meta);
                } else {
                    if (response.message) error_message = response.message;

                    toastError(error_message);
                }
                
            } catch(e) {
                if (e.message) error_message = e.message;

                toastError(error_message);
            }
        },
        async loadBlogCategoryDetail({ commit }, categoryId) {
            let error_message = 'Не удалось загрузить категорию блога';

            try {
                const response = await this.$axios.get(`/crm/blog-categories/${categoryId}`);

                if (response) {
                    if (response.data.data) commit('setBlogCategoryDetail', response.data);
                } else {
                    commit('setBlogCategoryDetail', null);
                }
                
            } catch(e) {
                if (e.message) error_message = e.message;

                toastError(error_message);
            }
        },
        async deleteBlogCategory({ dispatch }, categoryId) {
            let error_message = 'Не удалось удалить категорию блога';

            try {
                const response = await this.$axios.delete(`/crm/blog-categories/${categoryId}`);
                return response;
            } catch(e) {
                if (e.message) error_message = e.message;

                toastError(error_message);
            }
        },
    }
}